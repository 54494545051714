.my-masonry-grid {
  display: flex;
  /* margin-left: -20px; */
}
.my-masonry-grid_column {
  /* border-left: 20px solid transparent; */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin: 20px 12px;
  border-radius: 15px;
  box-shadow: 4px 4px 16px -5px rgba(0, 0, 0, 0.16);
  /* margin-bottom: 20px; */
}
